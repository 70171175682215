import { updateItem } from './updateItem'

export const TOGGLE_PROCESSED = 'TOGGLE_PROCESSED'

export const togglingProcessed = (item) => {
  console.log('Toggle processed on item: ', item.id)
  return {
    type: TOGGLE_PROCESSED,
    payload: { item },
  }
}

export const toggleProcessed = (item) => {
  return (dispatch) => {
    dispatch(togglingProcessed(item))
    const itemData = {
      processings_attributes: [
        { id: item.current_processing.id, processed: !item.current_processing.processed },
      ],
    }
    dispatch(updateItem(item.current_processing.workspace_id, item.id, itemData))
  }
}
