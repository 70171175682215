import { MODE_EDIT } from '../constants/workspaceConstants'

const initialState = {
  mode: MODE_EDIT,
  workspaceId: null,
  hasCategories: false,
  itemIdsToDisplay: [],
}

export default function (state = initialState) {
  return state
}
