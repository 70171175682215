import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Alert } from 'react-bootstrap'

import ReactResizeDetector from 'react-resize-detector'
import { debounce } from 'throttle-debounce'

import {
  MODE_SEARCH,
  MODE_SEARCH_ONLY,
  MODE_SHOW,
  MODE_EDIT,
} from '../constants/workspaceConstants'
import jcssc from '../../util/joined-css-class'

import ItemContainer from '../containers/ItemContainer'
import ItemListNavigation from './ItemListNavigation'

class ItemList extends Component {
  constructor() {
    super()
    this.handleResize = debounce(100, this.handleResize)
  }

  handleResize(width, height) {
    this.props.resizeItemListItems(width, height)
  }

  componentDidMount() {
    this.props.fetchItems(this.props.workspaceId, this.props.itemIdsToDisplay)
    this.props.fetchBundles(this.props.workspaceId)
    if (this.props.hasCategories) {
      this.props.fetchCategories(this.props.workspaceId)
    }
    this.props.fetchSources(this.props.workspaceId)
  }

  componentWillUnmount() {
    this.handleResize.cancel()
  }

  itemMode() {
    return this.props.mode == MODE_SEARCH || this.props.mode == MODE_SEARCH_ONLY
      ? MODE_SHOW
      : MODE_EDIT
  }

  itemModeChangable() {
    return this.props.mode == MODE_SEARCH
  }

  cssClass() {
    return jcssc(['item-list', this.props.className])
  }

  render() {
    return (
      <div className={this.cssClass()}>
        <div className="resize-detector">
          {this.renderItems()}
          <ReactResizeDetector handleHeight onResize={this.handleResize.bind(this)} />
        </div>
        <ItemListNavigation
          items={this.props.items}
          itemListNav={this.props.itemListNav}
          scrollTopChanged={this.props.scrollTopChanged}
          onNavStart={this.props.navigatingItemListNavStarted}
          onNavStop={this.props.navigatingItemListNavStopped}
        />
      </div>
    )
  }

  renderItems() {
    const items = this.props.items
    if (items.error) {
      const error = items.error
      return (
        <Alert bsStyle="danger">
          <h4>There was an error loading the items.</h4>
          <p>Please try to relaod this page. If the error persists, contact technical support.</p>
          <p>
            Error details ({error.textStatus}):
            <br />
            {error.error} ({error.responseText}).
          </p>
        </Alert>
      )
    } else if (items.loading) {
      return <div className="loading-sign">loading...</div>
    } else {
      return this.props.items.ids.map((itemId) => {
        let itemContainerProps = {
          mode: this.itemMode(),
          modeChangable: this.itemModeChangable(),
          item: this.props.items.byId[itemId],
          itemState: this.props.items.stateById[itemId],
          bundles: this.props.bundles,
          categories: this.props.categories,
          hasCategories: this.props.hasCategories,
          sources: this.props.sources,
        }

        return (
          <div key={itemId} className="items-list-entry">
            <ItemContainer {...itemContainerProps} />
          </div>
        )
      })
    }
  }
}

ItemList.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.string,
  fetchItems: PropTypes.func,
  fetchBundles: PropTypes.func,
  fetchCategories: PropTypes.func,
  fetchSources: PropTypes.func,
  items: PropTypes.object,
  itemListNav: PropTypes.object,
  scrollTopChanged: PropTypes.func,
  resizeItemListItems: PropTypes.func,
  navigatingItemListNavStarted: PropTypes.func,
  navigatingItemListNavStopped: PropTypes.func,
  bundles: PropTypes.object,
  sources: PropTypes.object,
  workspaceId: PropTypes.number,
  hasCategories: PropTypes.bool,
  categories: PropTypes.object,
  itemIdsToDisplay: PropTypes.array,
}

export default ItemList
