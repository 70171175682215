import update from 'immutability-helper'
import {
  FETCHING_BUNDLES,
  FETCHING_BUNDLES_FAILURE,
  FETCHING_BUNDLES_SUCCESS,
} from '../actions/fetchBundles'

const initialState = {
  byId: {},
  ids: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case FETCHING_BUNDLES:
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      })
    case FETCHING_BUNDLES_FAILURE:
      return update(state, {
        loading: { $set: false },
        error: { $set: payload },
      })
    case FETCHING_BUNDLES_SUCCESS:
      return update(state, {
        byId: { $set: payload.bundlesById },
        ids: { $set: payload.bundleIds },
        loading: { $set: false },
        error: { $set: null },
      })
    default:
      return state
  }
}
