import { updateItem } from './updateItem'

// REVIEW: should we rename these actions? updateReach vs. updateItem vs. updateItemReach vs. toggleRedundant ...
export const UPDATE_REACH = 'UPDATE_REACH'

export const updatingReach = (item, new_reach) => {
  console.log('Update item: ' + item.id + ' reach to ' + new_reach)
  return {
    type: UPDATE_REACH,
    payload: { item, new_reach },
  }
}

export const updateReach = (item, new_reach) => {
  return (dispatch) => {
    dispatch(updatingReach(item))
    const itemData = { reach: new_reach }
    dispatch(updateItem(item.current_processing.workspace_id, item.id, itemData))
  }
}
