import { updateItem } from './updateItem'

export const TOGGLE_CATEGORY = 'TOGGLE_CATEGORY'

export const togglingCategory = (item, category) => {
  console.log('You toggled Category: ', category.id)
  return {
    type: 'TOGGLE_CATEGORY',
    payload: { item, category },
  }
}

export const toggleCategory = (item, category) => {
  return (dispatch) => {
    let categoryIds = [...item.category_ids]
    const categoryIndex = categoryIds.indexOf(category.id)

    categoryIndex >= 0 ? categoryIds.splice(categoryIndex, 1) : categoryIds.push(category.id)

    // prevent deletion of empty array param
    if (categoryIds.length == 0) {
      categoryIds = ['']
    }

    const itemData = { category_ids: categoryIds }
    dispatch(updateItem(item.current_processing.workspace_id, item.id, itemData))
    dispatch(togglingCategory(item, category))
  }
}
