import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ListGroup, ListGroupItem } from 'react-bootstrap'

import jcssc from '../../util/joined-css-class'

import Tag from './Tag'

// REVIEW: Rename component to TagXXX not 'Panel'
class TagPanel extends Component {
  cssClass() {
    return jcssc(['tag-panel', this.props.className])
  }

  render() {
    return (
      <div className={this.cssClass()}>
        <ListGroup>{this.renderTags()}</ListGroup>
      </div>
    )
  }

  renderTags() {
    return this.props.tags.map((tag) => {
      const tagProps = {
        disabled: this.props.disabled,
        tag: tag,
        itemState: this.props.itemState,
        itemTagValueIds: this.props.itemTagValueIds,
        onTagValueClick: this.props.onTagValueClick,
      }

      return (
        <ListGroupItem key={tag.id}>
          <Tag {...tagProps} />
        </ListGroupItem>
      )
    })
  }
}

TagPanel.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  tags: PropTypes.array,
  itemState: PropTypes.object,
  itemTagValueIds: PropTypes.array,
  onTagValueClick: PropTypes.func,
}

export default TagPanel
