import update from 'immutability-helper'

import {
  FETCHING_ITEMS,
  FETCHING_ITEMS_FAILURE,
  FETCHING_ITEMS_SUCCESS,
} from '../actions/fetchItems'
import { UPDATING_ITEM, UPDATING_ITEM_SUCCESS, UPDATING_ITEM_FAILURE } from '../actions/updateItem'

import { SET_ITEM_CLIENT_ERRORS } from '../actions/setItemClientErrors'
import { TOGGLE_CATEGORY } from '../actions/toggleCategory'
import { TOGGLE_FAVOURITE } from '../actions/toggleFavourite'
import { TOGGLE_AUTOMATIC } from '../actions/toggleAutomatic'
import { TOGGLE_IRRELEVANT } from '../actions/toggleIrrelevant'
import { TOGGLE_PROCESSED } from '../actions/toggleProcessed'
import { TOGGLE_REDUNDANT } from '../actions/toggleRedundant'
import { TOGGLE_TAG_VALUE } from '../actions/toggleTagValue'
import { UPDATE_REACH } from '../actions/updateReach'

const initialState = {
  byId: {},
  stateById: {},
  ids: [],
  loading: false,
  error: null,
}

function toggleProcessingAttr(state, itemId, attrName) {
  const item = state.byId[itemId]

  return update(state, {
    byId: {
      [itemId]: {
        current_processing: {
          $merge: { [attrName]: !item.current_processing[attrName] },
        },
      },
    },
  })
}

function toggleCategory(state, itemId, category) {
  const item = state.byId[itemId]
  const categoryIndex = item.category_ids.indexOf(category.id)
  const operation = categoryIndex >= 0 ? '$splice' : '$push'
  const operand = operation == '$push' ? [category.id] : [[categoryIndex, 1]]

  return update(state, {
    byId: {
      [itemId]: {
        category_ids: {
          [operation]: operand,
        },
      },
    },
  })
}

function toggleTagValue(state, itemId, _tag, tagValue) {
  // REVIEW: the remote part of this (cf. toggleTagValue action creator) deselects other tag values of this tag;
  //   this reducer does not (this is usually not visible to the user for very long)
  const item = state.byId[itemId]
  const tagValueIndex = item.tag_value_ids.indexOf(tagValue.id)
  const operation = tagValueIndex >= 0 ? '$splice' : '$push'
  const operand = operation == '$push' ? [tagValue.id] : [[tagValueIndex, 1]]

  return update(state, {
    byId: {
      [itemId]: {
        tag_value_ids: {
          [operation]: operand,
        },
      },
    },
  })
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case FETCHING_ITEMS:
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      })
    case FETCHING_ITEMS_FAILURE:
      return update(state, {
        loading: { $set: false },
        error: { $set: payload },
      })
    case FETCHING_ITEMS_SUCCESS: {
      const stateByIds = payload.itemIds.reduce((obj, itemId) => {
        obj[itemId] = { updating: false, error: null, clientErrors: {} }
        return obj
      }, {})
      return update(state, {
        byId: { $set: payload.itemsById },
        ids: { $set: payload.itemIds },
        stateById: { $set: stateByIds },
        loading: { $set: false },
        error: { $set: null },
      })
    }
    case SET_ITEM_CLIENT_ERRORS:
      return update(state, {
        stateById: {
          [payload.item.id]: {
            $set: { clientErrors: payload.clientErrors },
          },
        },
      })
    case UPDATING_ITEM:
      return update(state, {
        stateById: {
          [payload.id]: {
            $merge: { updating: true, error: null },
          },
        },
      })
    case UPDATING_ITEM_SUCCESS:
      return update(state, {
        byId: {
          [payload.id]: { $set: payload.item },
        },
        stateById: {
          [payload.id]: { $merge: { updating: false, error: null, clientErrors: {} } },
        },
      })
    case UPDATING_ITEM_FAILURE:
      return update(state, {
        stateById: {
          [payload.id]: { $merge: { updating: false, error: payload, clientErrors: {} } },
        },
      })
    case TOGGLE_CATEGORY:
      return toggleCategory(state, payload.item.id, payload.category)
    case TOGGLE_FAVOURITE:
      return toggleProcessingAttr(state, payload.item.id, 'favourite')
    case TOGGLE_AUTOMATIC:
      return toggleProcessingAttr(state, payload.item.id, 'automatic')
    case TOGGLE_IRRELEVANT:
      return toggleProcessingAttr(state, payload.item.id, 'irrelevant')
    case TOGGLE_PROCESSED:
      return toggleProcessingAttr(state, payload.item.id, 'processed')
    case TOGGLE_REDUNDANT:
      return toggleProcessingAttr(state, payload.item.id, 'redundant')
    case TOGGLE_TAG_VALUE:
      return toggleTagValue(state, payload.item.id, payload.tag, payload.tagValue)
    case UPDATE_REACH:
      return update(state, {
        byId: {
          [payload.item.id]: {
            reach: { $set: payload.reach },
          },
        },
      })
    default:
      return state
  }
}
