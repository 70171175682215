export const RESIZE_ITEM_LIST_ITEMS = 'RESIZE_ITEM_LIST_ITEMS'

// note that, depending on the information the caller provides, one of the parameters may be undefined
export const resizeItemListItems = (width, height) => {
  console.log(`Resizing item list items: ${width} x ${height}`)
  return {
    type: RESIZE_ITEM_LIST_ITEMS,
    payload: { width, height },
  }
}
