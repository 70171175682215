export const FETCHING_SOURCES = 'FETCHING_SOURCE'
export const FETCHING_SOURCES_SUCCESS = 'FETCHING_SOURCES_SUCCESS'
export const FETCHING_SOURCES_FAILURE = 'FETCHING_SOURCES_FAILURE'

export const getSources = (workspaceId) => {
  console.log(`Getting sources for workspace: ${workspaceId}`)
  return {
    type: FETCHING_SOURCES,
    payload: { workspaceId },
  }
}

export const getSourcesSuccess = (workspaceId, sources) => {
  console.log(`Getting sources success for workspace: ${workspaceId} (sources: ${sources})`)
  const sourceIds = sources.map((source) => {
    return source.id
  })
  const sourcesById = sources.reduce((obj, source) => {
    obj[source.id] = source
    return obj
  }, {})
  return {
    type: FETCHING_SOURCES_SUCCESS,
    payload: { workspaceId, sourceIds, sourcesById },
  }
}

export const getSourcesFailure = (workspaceId, textStatus, error, responseText) => {
  console.log(
    `Getting sources failure for workspace: ${workspaceId} (${textStatus} (${error}); response: ${responseText})`
  )
  return {
    type: FETCHING_SOURCES_FAILURE,
    payload: { workspaceId, textStatus, error, responseText },
  }
}

export const fetchSources = (workspaceId) => {
  console.log(`Fetching sources for workspace: ${workspaceId}`)
  return (dispatch) => {
    dispatch(getSources(workspaceId))
    jQuery
      .getJSON(`/admin/workspaces/${workspaceId}/sources.json`) // FIXME: routing? move to s/th like 'api.js'?
      .done((data) => {
        dispatch(getSourcesSuccess(workspaceId, data))
      })
      .fail((jqxhr, textStatus, error) => {
        dispatch(getSourcesFailure(workspaceId, textStatus, error, jqxhr.responseText))
      })
  }
}
