export const UPDATING_ITEM = 'UPDATING_ITEM'
export const UPDATING_ITEM_SUCCESS = 'UPDATING_ITEM_SUCCESS'
export const UPDATING_ITEM_FAILURE = 'UPDATING_ITEM_FAILURE'

export const updatingItem = (workspaceId, id, data) => {
  console.log(`Updating item: ${id} with data ${data}`)
  return {
    type: UPDATING_ITEM,
    payload: { workspaceId, id, data },
  }
}

export const updatingItemSuccess = (workspaceId, id, item) => {
  console.log(`Update item success in workspace ${workspaceId} for item ${id}: ${item}`)
  return {
    type: UPDATING_ITEM_SUCCESS,
    payload: { workspaceId, id, item },
  }
}

export const updatingItemFailure = (workspaceId, id, textStatus, error, responseText) => {
  console.log(
    `Update item failure in workspace ${workspaceId} for item ${id}: (${textStatus} (${error}); response: ${responseText})`
  )
  return {
    type: UPDATING_ITEM_FAILURE,
    payload: { workspaceId, id, textStatus, error, responseText },
  }
}

// Just like jQuery.ajax, but retries the request on failure. Returns an ES6 promise.
//
// Example:
//     new AjaxWithRetry(mySettings).
//       request().
//       then((data) -> { console.log(data) }).
//       catch((errors) -> { console.log(errors) });
//
// By default, it retries up to 3 times, while waiting 1*1.5 / 2*1.5 / 3*1.5 seconds before each retry.
// You may specify a boolean function to check whether you want to perform a specific retry
// (will get an object of the last {jqxhr, textStatus, error}).
// The things mentioned above can be configured.
//
// You may call request() only once.
// If the promise gets resolved, it will return the data; if it gets rejected, it returns an array of
// error objects (one for each performed request, in the form {jqxhr, textStatus, error}).
class AjaxWithRetry {
  constructor(
    settings,
    { retryCount = 3, retryWait = 1.5, increasingRetries = true, retryIfFn = null } = {}
  ) {
    this.settings = settings
    this.retryCount = retryCount
    this.retryWait = retryWait
    this.errors = []
    this.increasingRetries = increasingRetries
    this.retryIfFn =
      retryIfFn ||
      (() => {
        return true
      })

    this.currentAttempt = 0
  }

  request() {
    if (this.currentAttempt > 0) {
      throw 'Sorry someone already called request()... '
    }
    let p = this.asyncRequest()
    for (let i = 0; i < this.retryCount; i++) {
      p = p.catch(this.asyncRequest.bind(this))
    }
    return p
  }

  asyncRequest() {
    return new Promise((resolve, reject) => {
      this.currentAttempt += 1

      if (this.currentAttempt == 1 || this.retryIfFn(this.errors[this.errors.length - 1])) {
        console.log(`Executing request (attempt ${this.currentAttempt}/${this.retryCount + 1})`)
        let timeoutSeconds = 0
        if (this.currentAttempt > 1) {
          let factor = this.increasingRetries ? this.currentAttempt - 1 : 1
          timeoutSeconds = this.retryWait * factor
        }
        console.log(`  (waiting ${timeoutSeconds} seconds before doing that...)`)

        window.setTimeout(() => {
          jQuery
            .ajax(this.settings)
            .done((data) => {
              console.log('Request done!')
              resolve(data)
            })
            .fail((jqxhr, textStatus, error) => {
              console.log('Request failed :/')
              this.errors.push({ jqxhr, textStatus, error })
              reject(this.errors)
            })
        }, timeoutSeconds * 1000)
      } else {
        console.log(
          `Skipping retry attempt ${this.currentAttempt}/${this.retryCount + 1} for request.`
        )
        reject(this.errors)
      }
    })
  }
}

export const updateItem = (workspaceId, id, itemDataToUpdate) => {
  console.log(`Update item ${id} requested`)
  return (dispatch) => {
    dispatch(updatingItem(workspaceId, id, itemDataToUpdate))

    const requestData = {
      _method: 'PUT',
      item: itemDataToUpdate,
    }

    const settings = {
      type: 'POST',
      url: `/admin/workspaces/${workspaceId}/items/${id}.json`, // FIXME: routing? move to s/th like 'api.js'?
      data: requestData,
    }

    // on which errors to retry the request
    const retryIfFn = ({ jqxhr = null } = {}) => {
      const status = jqxhr.status
      return (
        // request was not sent because e.g. no internet connection / no dns resolve / connection interrupted / ...:
        (status === 0 && jqxhr.readyState === 0) ||
        // bad gateway, service unavailable, gateway timeout:
        status == 502 ||
        status == 503 ||
        status == 504
      )
    }

    new AjaxWithRetry(settings, { retryIfFn })
      .request()
      .then((data) => {
        dispatch(updatingItemSuccess(workspaceId, id, data))
      })
      .catch((errors) => {
        const { jqxhr, error } = errors[0] // get stuff from first error
        const extendedTextStatus =
          `Attempted ${errors.length} times: ` +
          errors
            .map((e, i) => {
              return `Attempt ${i + 1}: ${e.textStatus}, status ${e.jqxhr.status}, ready state ${
                e.jqxhr.readyState
              }`
            })
            .join('; ') +
          '; any following details shown are from attempt 1'

        dispatch(
          updatingItemFailure(workspaceId, id, extendedTextStatus, error, jqxhr.responseText)
        )
      })
  }
}
