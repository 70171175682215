import { updateItem } from './updateItem'

export const TOGGLE_AUTOMATIC = 'TOGGLE_AUTOMATIC'

export const togglingAutomatic = (item) => {
  console.log('Toggle automatic on item: ', item.id)
  return {
    type: TOGGLE_AUTOMATIC,
    payload: { item },
  }
}

export const toggleAutomatic = (item) => {
  return (dispatch) => {
    dispatch(togglingAutomatic(item))
    const itemData = {
      processings_attributes: [
        { id: item.current_processing.id, automatic: !item.current_processing.automatic },
      ],
    }
    dispatch(updateItem(item.current_processing.workspace_id, item.id, itemData))
  }
}
