export const FETCHING_CATEGORIES = 'FETCHING_CATEGORIES'
export const FETCHING_CATEGORIES_SUCCESS = 'FETCHING_CATEGORIES_SUCCESS'
export const FETCHING_CATEGORIES_FAILURE = 'FETCHING_CATEGORIES_FAILURE'

export const getCategories = (workspaceId) => {
  console.log(`Getting categories for workspace: ${workspaceId}`)
  return {
    type: FETCHING_CATEGORIES,
    payload: { workspaceId },
  }
}

export const getCategoriesSuccess = (workspaceId, categories) => {
  console.log(
    `Getting categories success for workspace: ${workspaceId} (categories: ${categories})`
  )
  const categoryIds = categories.map((category) => {
    return category.id
  })
  const categoriesById = categories.reduce((obj, category) => {
    obj[category.id] = category
    return obj
  }, {})
  return {
    type: FETCHING_CATEGORIES_SUCCESS,
    payload: { workspaceId, categoryIds, categoriesById },
  }
}

export const getCategoriesFailure = (workspaceId, textStatus, error, responseText) => {
  console.log(
    `Getting categories failure for workspace: ${workspaceId} (${textStatus} (${error}); response: ${responseText})`
  )
  return {
    type: FETCHING_CATEGORIES_FAILURE,
    payload: { workspaceId, textStatus, error, responseText },
  }
}

export const fetchCategories = (workspaceId) => {
  console.log(`Fetching categories for workspace: ${workspaceId}`)
  return (dispatch) => {
    dispatch(getCategories(workspaceId))
    jQuery
      .getJSON(`/admin/workspaces/${workspaceId}/categories.json`) // FIXME: routing? move to s/th like 'api.js'?
      .done((data) => {
        dispatch(getCategoriesSuccess(workspaceId, data))
      })
      .fail((jqxhr, textStatus, error) => {
        dispatch(getCategoriesFailure(workspaceId, textStatus, error, jqxhr.responseText))
      })
  }
}
