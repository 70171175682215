import update from 'immutability-helper'
import {
  FETCHING_SOURCES,
  FETCHING_SOURCES_FAILURE,
  FETCHING_SOURCES_SUCCESS,
} from '../actions/fetchSources'

const initialState = {
  byId: {},
  ids: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case FETCHING_SOURCES:
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      })
    case FETCHING_SOURCES_FAILURE:
      return update(state, {
        loading: { $set: false },
        error: { $set: payload },
      })
    case FETCHING_SOURCES_SUCCESS:
      return update(state, {
        byId: { $set: payload.sourcesById },
        ids: { $set: payload.sourceIds },
        loading: { $set: false },
        error: { $set: null },
      })
    default:
      return state
  }
}
