import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Grid, Row, Col, Panel, Glyphicon } from 'react-bootstrap'

import { MODE_EDIT } from '../constants/workspaceConstants'
import jcssc from '../../util/joined-css-class'
import partition from '../../util/array-partition'
import inGroupsOf from '../../util/array-in-groups-of'

import TagList from './TagList'
import TagPanel from './TagPanel'

class Bundle extends Component {
  constructor(props) {
    super(props)
    this.state = { expanded: this.props.expanded ? this.props.expanded : false }
  }

  toggleExpandedState() {
    this.setState({ expanded: !this.state.expanded })
  }

  getTags() {
    return this.props.bundle.ordered_tags == null ? [] : this.props.bundle.ordered_tags
  }

  getSelectedTags() {
    return this.getTags().filter((tag) => {
      const tagValueIds = tag.ordered_tag_values.map((tagValue) => {
        return tagValue.id
      })
      return tagValueIds.some((tagValueId) => this.props.itemTagValueIds.indexOf(tagValueId) >= 0)
    })
  }

  // Returns all tags structured like this:
  //   {
  //    important: [(important or mandatory tags)],
  //    regulars: [[(first half of the rest)], [(second half of the rest)]]
  //   }
  getStructuredTags() {
    const [important, regular] = partition(this.getTags(), (tag) => {
      return tag.important || tag.mandatory
    })
    const regulars = inGroupsOf(regular, Math.ceil(regular.length / 2))

    return { important, regulars }
  }

  cssClass() {
    return jcssc(['bundle', this.props.className])
  }

  render() {
    return this.props.mode == MODE_EDIT ? this.renderEditMode() : this.renderShowMode()
  }

  renderShowMode() {
    const tagListProps = {
      tags: this.getSelectedTags(),
      itemState: this.props.itemState,
      itemTagValueIds: this.props.itemTagValueIds,
    }

    return (
      <div className={this.cssClass()}>
        <Panel>
          <Panel.Heading>
            <Panel.Title>{this.props.bundle.name}</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <TagList {...tagListProps}></TagList>
          </Panel.Body>
        </Panel>
      </div>
    )
  }

  renderEditMode() {
    const structuredTags = this.getStructuredTags()

    let tagPanelProps = {
      className: 'tag-panel-primary',
      disabled: this.props.disabled,
      tags: structuredTags.important,
      itemState: this.props.itemState,
      itemTagValueIds: this.props.itemTagValueIds,
      onTagValueClick: this.props.onTagValueClick,
    }

    return (
      <div className={this.cssClass()}>
        <Panel expanded={this.state.expanded} onToggle={() => this.toggleExpandedState()}>
          <Panel.Heading>
            <Panel.Title toggle>{this.renderEditHeader()}</Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
            <TagPanel {...tagPanelProps} />
            <Grid>
              <Row>{this.renderRegularTagCols(structuredTags.regulars)}</Row>
            </Grid>
          </Panel.Body>
        </Panel>
      </div>
    )
  }

  renderEditHeader() {
    return (
      <div>
        <Glyphicon glyph={this.state.expanded ? 'triangle-bottom' : 'triangle-right'} />{' '}
        {this.props.bundle.name}
      </div>
    )
  }

  renderRegularTagCols(tagGroups) {
    return tagGroups.map((tags, index) => {
      const tagPanelProps = {
        disabled: this.props.disabled,
        tags: tags,
        itemState: this.props.itemState,
        itemTagValueIds: this.props.itemTagValueIds,
        onTagValueClick: this.props.onTagValueClick,
      }

      return (
        <Col key={index} md={6}>
          <TagPanel {...tagPanelProps} />
        </Col>
      )
    })
  }
}

Bundle.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
  expanded: PropTypes.bool,
  bundle: PropTypes.object,
  itemState: PropTypes.object,
  itemTagValueIds: PropTypes.array,
  onTagValueClick: PropTypes.func,
}

export default Bundle
