import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

import jcssc from '../../util/joined-css-class'

class Category extends Component {
  constructor(props) {
    super(props)
    this.state = { className: this.props.className ? this.props.className : '' }
  }

  isActive() {
    if (this.props.itemCategoryIds == undefined) {
      return false
    } else {
      return (
        this.props.itemCategoryIds.find((categoryId) => {
          return categoryId == this.props.category.id
        }) != undefined
      )
    }
  }

  cssClass() {
    return jcssc(['category', this.props.className])
  }

  isActiveClass() {
    return this.isActive() ? 'active' : ''
  }

  render() {
    let buttonProps = {
      active: this.isActive(),
      disabled: this.props.disabled || this.props.onClick == undefined,
    }

    if (this.props.onClick != undefined) {
      buttonProps.onClick = () => this.props.onClick(this.props.category)
    }

    return (
      <div className={this.cssClass()}>
        <Button {...buttonProps}>{this.props.category.name}</Button>
      </div>
    )
  }
}

Category.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
  category: PropTypes.object,
  itemState: PropTypes.object,
  itemCategoryIds: PropTypes.array,
  onClick: PropTypes.func,
}

export default Category
