import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Alert, Panel } from 'react-bootstrap'

import jcssc from '../../util/joined-css-class'

import Category from './Category'

class CategoryList extends Component {
  getCategories() {
    if (this.props.categories.ids.length > 0) {
      return this.props.categories.ids.map((categoryId) => {
        return this.props.categories.byId[categoryId]
      })
    } else {
      return []
    }
  }

  getSelectedCategories() {
    const categories = this.getCategories()

    if (categories.length > 0 && this.props.itemCategoryIds.length > 0) {
      return categories.filter((category) => {
        return this.props.itemCategoryIds.indexOf(category.id) >= 0
      })
    } else {
      return []
    }
  }

  cssClass() {
    return jcssc(['category-list', this.props.className])
  }

  render() {
    return (
      <div className={this.cssClass()}>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Categories</Panel.Title>
          </Panel.Heading>
          <Panel.Body>{this.renderCategoryList()}</Panel.Body>
        </Panel>
      </div>
    )
  }

  renderCategoryList() {
    const categories = this.props.categories
    if (categories.error) {
      const error = categories.error
      return (
        <Alert bsStyle="danger">
          <h4>There was an error loading the categories.</h4>
          <p>Please try to relaod this page. If the error persists, contact technical support.</p>
          <p>
            Error details ({error.textStatus}):
            <br />
            {error.error} ({error.responseText}).
          </p>
        </Alert>
      )
    } else if (categories.loading) {
      return <div className="loading-sign">loading...</div>
    } else {
      return (
        <div>
          <ul>{this.renderCategories()}</ul>
        </div>
      )
    }
  }

  renderCategories() {
    return this.getSelectedCategories().map((category) => {
      let categoryProps = {
        className: 'category-' + this.props.mode,
        key: category.id,
        mode: this.props.mode,
        category: category,
        itemState: this.props.itemState,
        itemCategoryIds: this.props.itemCategoryIds,
      }

      return (
        <li key={category.id}>
          <Category {...categoryProps} />
        </li>
      )
    })
  }
}

CategoryList.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.string,
  categories: PropTypes.object,
  itemState: PropTypes.object,
  itemCategoryIds: PropTypes.array,
}

export default CategoryList
