export const NAVIGATING_ITEM_LIST_NAV_STARTED = 'NAVIGATING_ITEM_LIST_NAV_STARTED'
export const NAVIGATING_ITEM_LIST_NAV_STOPPED = 'NAVIGATING_ITEM_LIST_NAV_STOPPED'

export const navigatingItemListNavStarted = (itemId) => {
  console.log(`Navigating item list nav started: ${itemId}`)
  return {
    type: NAVIGATING_ITEM_LIST_NAV_STARTED,
    payload: { itemId },
  }
}

export const navigatingItemListNavStopped = (itemId) => {
  console.log(`Navigating item list nav stopped: ${itemId}`)
  return {
    type: NAVIGATING_ITEM_LIST_NAV_STOPPED,
    payload: { itemId },
  }
}
