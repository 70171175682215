import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ButtonToolbar } from 'react-bootstrap'

import jcssc from '../../util/joined-css-class'

import TagValue from './TagValue'

class TagValueList extends Component {
  cssClass() {
    return jcssc(['tag-value-list', this.props.className])
  }

  render() {
    return (
      <div className={this.cssClass()}>
        <ButtonToolbar>{this.renderTagValues()}</ButtonToolbar>
      </div>
    )
  }

  renderTagValues() {
    return this.props.tagValues.map((tagValue, index) => {
      let tagValueProps = {
        tagValue: tagValue,
        disabled: this.props.disabled,
        itemTagValueIds: this.props.itemTagValueIds,
        onClick: this.props.onTagValueClick,
      }

      if (this.props.isTagMoodTag) {
        tagValueProps.className = this.visualTagValueClasses()[index]
      }

      return <TagValue key={tagValue.id} {...tagValueProps} />
    })
  }

  // NOTE: CSS classe should be english though ...
  visualTagValueClasses() {
    return ['positive', 'neutral', 'negative']
  }
}

TagValueList.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  tagValues: PropTypes.array,
  isTagMoodTag: PropTypes.bool,
  itemTagValueIds: PropTypes.array,
  onTagValueClick: PropTypes.func,
}

export default TagValueList
