export const FETCHING_ITEMS = 'FETCHING_ITEMS'
export const FETCHING_ITEMS_SUCCESS = 'FETCHING_ITEMS_SUCCESS'
export const FETCHING_ITEMS_FAILURE = 'FETCHING_ITEMS_FAILURE'

export const getItems = (workspaceId) => {
  console.log(`Getting items for workspace: ${workspaceId}`)
  return {
    type: FETCHING_ITEMS,
    payload: { workspaceId },
  }
}

export const getItemsSuccess = (workspaceId, items) => {
  console.log(`Getting items success for workspace: ${workspaceId} (items: ${items})`)
  const itemIds = items.map((item) => {
    return item.id
  })
  const itemsById = items.reduce((obj, item) => {
    obj[item.id] = item
    return obj
  }, {})
  return {
    type: FETCHING_ITEMS_SUCCESS,
    payload: { workspaceId, itemIds, itemsById },
  }
}

export const getItemsFailure = (workspaceId, textStatus, error, responseText) => {
  console.log(
    `Getting items failure for workspace: ${workspaceId} (${textStatus} (${error}); response: ${responseText})`
  )
  return {
    type: FETCHING_ITEMS_FAILURE,
    payload: { workspaceId, textStatus, error, responseText },
  }
}

export const fetchItems = (workspaceId, itemIds) => {
  console.log(`Fetching items for workspace: ${workspaceId} (ids: ${itemIds})`)
  return (dispatch) => {
    dispatch(getItems(workspaceId))
    jQuery
      .getJSON(
        `/admin/workspaces/${workspaceId}/items.json`, // FIXME: routing? move to s/th like 'api.js'?
        {
          for_fe_components: true,
          item_ids: itemIds,
        }
      )
      .done((data) => {
        dispatch(getItemsSuccess(workspaceId, data))
      })
      .fail((jqxhr, textStatus, error) => {
        dispatch(getItemsFailure(workspaceId, textStatus, error, jqxhr.responseText))
      })
  }
}
