import { updateItem } from './updateItem'

export const TOGGLE_TAG_VALUE = 'TOGGLE_TAG_VALUE'

export const togglingTagValue = (item, tag, tagValue) => {
  console.log('You toggled TagValue: ', tagValue.id)
  return {
    type: 'TOGGLE_TAG_VALUE',
    payload: { item, tag, tagValue },
  }
}

export const toggleTagValue = (item, tag, tagValue) => {
  return (dispatch) => {
    let tagValueIds = [...item.tag_value_ids]
    const tagValueIndex = tagValueIds.indexOf(tagValue.id)

    if (tagValueIndex >= 0) {
      tagValueIds.splice(tagValueIndex, 1)
    } else {
      // make sure there is at most one tag value selected for this tag:
      tag.ordered_tag_values.map((tagTagValue) => {
        let i = tagValueIds.indexOf(tagTagValue.id)
        if (i >= 0) {
          tagValueIds.splice(i, 1)
        }
      })
      tagValueIds.push(tagValue.id)
    }

    // prevent deletion of empty array param
    if (tagValueIds.length == 0) {
      tagValueIds = ['']
    }

    const itemData = { tag_value_ids: tagValueIds }
    dispatch(updateItem(item.current_processing.workspace_id, item.id, itemData))
    dispatch(togglingTagValue(item, tag, tagValue))
  }
}
