import { updateItem } from './updateItem'

export const TOGGLE_REDUNDANT = 'TOGGLE_REDUNDANT'

export const togglingRedundant = (item) => {
  console.log('Toggle redundant on item: ', item.id)
  return {
    type: TOGGLE_REDUNDANT,
    payload: { item },
  }
}

export const toggleRedundant = (item) => {
  return (dispatch) => {
    dispatch(togglingRedundant(item))
    const itemData = {
      processings_attributes: [
        { id: item.current_processing.id, redundant: !item.current_processing.redundant },
      ],
    }
    dispatch(updateItem(item.current_processing.workspace_id, item.id, itemData))
  }
}
