import strftime from 'strftime'

// REVIEW: Currently localized, formatted date is rendered via strftime, should we use a proper I18n solution?
// @see: https://github.com/samsonjs/strftime
// I18n Alternative:
// @see: https://formatjs.io/react/v1/
// @see: https://github.com/shakacode/react_on_rails/blob/master/docs/basics/i18n.md
export default function (date) {
  const de_DE = {
    days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    months: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    shortMonths: [
      'gen',
      'feb',
      'mar',
      'apr',
      'mag',
      'giu',
      'lug',
      'ago',
      'set',
      'ott',
      'nov',
      'dic',
    ],
    AM: 'AM',
    PM: 'PM',
    am: 'am',
    pm: 'pm',
    formats: {
      D: '%d.%m.%y',
      F: '%d.%m.%Y',
      R: '%H:%M',
      X: '%T',
      c: '%a %b %d %X %Y',
      r: '%I:%M:%S %p',
      T: '%H:%M:%S',
      v: '%e-%b-%Y',
      x: '%D',
    },
  }

  const strftimeDe = strftime.localize(de_DE)
  return strftimeDe('%d.%m.%Y, %H:%M:%S, %A', date)
}
