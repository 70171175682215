import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { LINK_TARGET_ABOVE_ITEM } from '../constants/workspaceConstants'

import jcssc from '../../util/joined-css-class'

import itemStateClass from '../../util/item-state-class'

class ItemListNavigationEntry extends Component {
  cssClass() {
    return jcssc([
      'item-list-navigation-entry',
      itemStateClass(this.props.item, this.props.itemState),
      this.props.className,
    ])
  }

  itemDomId() {
    return `#item-${this.props.item.id}`
  }

  onNavStart() {
    this.props.onNavStart(this.props.item.id)
  }

  onNavStop() {
    this.props.onNavStop(this.props.item.id)
  }

  clickLink(event) {
    event.preventDefault()
    this.onNavStart()
    const scrollTop = $(this.itemDomId()).offset().top - LINK_TARGET_ABOVE_ITEM
    $('html,body').animate(
      { scrollTop },
      {
        duration: 'slow',
        always: this.onNavStop.bind(this),
      }
    )
  }

  render() {
    return (
      <a className={this.cssClass()} href={this.itemDomId()} onClick={this.clickLink.bind(this)}>
        {this.props.item.id}
      </a>
    )
  }
}

ItemListNavigationEntry.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  itemState: PropTypes.object,
  onNavStart: PropTypes.func,
  onNavStop: PropTypes.func,
}

export default ItemListNavigationEntry
