export const FETCHING_BUNDLES = 'FETCHING_BUNDLES'
export const FETCHING_BUNDLES_SUCCESS = 'FETCHING_BUNDLES_SUCCESS'
export const FETCHING_BUNDLES_FAILURE = 'FETCHING_BUNDLES_FAILURE'

export const getBundles = (workspaceId) => {
  console.log(`Getting bundles for workspace: ${workspaceId}`)
  return {
    type: FETCHING_BUNDLES,
    payload: { workspaceId },
  }
}

export const getBundlesSuccess = (workspaceId, bundles) => {
  console.log(`Getting bundles success for workspace: ${workspaceId} (bundles: ${bundles})`)
  const bundleIds = bundles.map((bundle) => {
    return bundle.id
  })
  const bundlesById = bundles.reduce((obj, bundle) => {
    obj[bundle.id] = bundle
    return obj
  }, {})
  return {
    type: FETCHING_BUNDLES_SUCCESS,
    payload: { workspaceId, bundleIds, bundlesById },
  }
}

export const getBundlesFailure = (workspaceId, textStatus, error, responseText) => {
  console.log(
    `Getting bundles failure for workspace: ${workspaceId} (${textStatus} (${error}); response: ${responseText})`
  )
  return {
    type: FETCHING_BUNDLES_FAILURE,
    payload: { workspaceId, textStatus, error, responseText },
  }
}

export const fetchBundles = (workspaceId) => {
  console.log(`Fetching bundles for workspace: ${workspaceId}`)
  return (dispatch) => {
    dispatch(getBundles(workspaceId))
    jQuery
      .getJSON(`/admin/workspaces/${workspaceId}/bundles.json`) // FIXME: routing? move to s/th like 'api.js'?
      .done((data) => {
        dispatch(getBundlesSuccess(workspaceId, data))
      })
      .fail((jqxhr, textStatus, error) => {
        dispatch(getBundlesFailure(workspaceId, textStatus, error, jqxhr.responseText))
      })
  }
}
