export default function (item, itemState) {
  let state = 'unprocessed'

  const hasError = Object.getOwnPropertyNames(itemState.clientErrors).length > 0 || itemState.error

  // mind class priority - since item can only be represented by one class
  if (item.current_processing.processed) {
    state = 'complete'
  }
  if (item.current_processing.irrelevant) {
    state = 'ignore'
  }
  if (item.current_processing.redundant) {
    state = 'ignore'
  }
  if (hasError) {
    state = 'error'
  }

  return state
}
