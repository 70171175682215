import PropTypes from 'prop-types'
import React, { Component } from 'react'

import jcssc from '../../util/joined-css-class'

import Tag from './Tag'

class TagList extends Component {
  cssClass() {
    return jcssc(['tag-list', this.props.className])
  }

  render() {
    return <div className={this.cssClass()}>{this.renderTagList()}</div>
  }

  renderTagList() {
    if (this.props.tags.length > 0) {
      return <ul>{this.renderTags()}</ul>
    }
  }

  renderTags() {
    return this.props.tags.map((tag) => {
      const tagProps = {
        tag: tag,
        itemState: this.props.itemState,
        itemTagValueIds: this.props.itemTagValueIds,
        onTagValueClick: this.props.onTagValueClick,
      }

      return (
        <li key={tag.id}>
          <Tag {...tagProps} />
        </li>
      )
    })
  }
}

TagList.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.array,
  itemState: PropTypes.object,
  itemTagValueIds: PropTypes.array,
  onTagValueClick: PropTypes.func,
}

export default TagList
