// REVIEW: move ItemList component code in this file and rename this file to ItemList?

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { fetchBundles } from '../actions/fetchBundles'
import { fetchCategories } from '../actions/fetchCategories'
import { fetchSources } from '../actions/fetchSources'
import { fetchItems } from '../actions/fetchItems'
import { resizeItemListItems } from '../actions/resizeItemListItems'
import { scrollTopChanged } from '../actions/scroll'
import {
  navigatingItemListNavStarted,
  navigatingItemListNavStopped,
} from '../actions/navigatingItemListNav'

import ItemList from '../components/ItemList'

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  bundles: state.bundles,
  categories: state.categories,
  sources: state.sources,
  mode: state.global.mode,
  items: state.items,
  workspaceId: state.global.workspaceId,
  hasCategories: state.global.hasCategories,
  itemIdsToDisplay: state.global.itemIdsToDisplay,
  itemListNav: state.itemListNav,
})

const matchDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchBundles,
      fetchCategories,
      fetchSources,
      fetchItems,
      resizeItemListItems,
      scrollTopChanged,
      navigatingItemListNavStarted,
      navigatingItemListNavStopped,
    },
    dispatch
  )

// Don't forget to actually use connect!
// Note that we don't export ItemList, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, matchDispatchToProps)(ItemList)
