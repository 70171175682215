import { updateItem } from './updateItem'

export const TOGGLE_FAVOURITE = 'TOGGLE_FAVOURITE'

export const togglingFavourite = (item) => {
  console.log('Toggle favorite on item: ', item.id)
  return {
    type: TOGGLE_FAVOURITE,
    payload: { item },
  }
}

export const toggleFavourite = (item) => {
  return (dispatch) => {
    dispatch(togglingFavourite(item))
    const itemData = {
      processings_attributes: [
        { id: item.current_processing.id, favourite: !item.current_processing.favourite },
      ],
    }
    dispatch(updateItem(item.current_processing.workspace_id, item.id, itemData))
  }
}
