import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Alert } from 'react-bootstrap'

import jcssc from '../../util/joined-css-class'

import TagValueList from './TagValueList'

class Tag extends Component {
  constructor(props) {
    super(props)
  }

  cssClass() {
    let cssClasses = ['tag', this.props.className]
    if (this.isMandatory()) {
      cssClasses.push('mandatory')
    }
    if (this.isMoodTag()) {
      cssClasses.push('sentiment')
    }
    if (this.getClientErrorForTag() != null) {
      cssClasses.push('error')
    }
    return jcssc(cssClasses)
  }

  isMandatory() {
    return this.props.tag.mandatory == true
  }

  isMoodTag() {
    return this.props.tag.type == 'MoodTag'
  }

  onTagValueClick(tagValue) {
    this.props.onTagValueClick(this.props.tag, tagValue)
  }

  render() {
    const tagValues =
      this.props.tag.ordered_tag_values == null ? [] : this.props.tag.ordered_tag_values

    let tagValueListProps = {
      tagValues: tagValues,
      disabled: this.props.disabled,
      isTagMoodTag: this.isMoodTag(),
      itemTagValueIds: this.props.itemTagValueIds,
    }

    if (this.props.onTagValueClick != undefined) {
      tagValueListProps.onTagValueClick = this.onTagValueClick.bind(this)
    }

    return (
      <div className={this.cssClass()}>
        <label>{this.props.tag.name}</label>
        <TagValueList {...tagValueListProps} />
        {this.renderValidationError()}
      </div>
    )
  }

  getClientErrorForTag() {
    const tagsErrorsById = this.props.itemState.clientErrors.tagsById
    return tagsErrorsById != null ? tagsErrorsById[this.props.tag.id] : null
  }

  renderValidationError() {
    const error = this.getClientErrorForTag()
    if (error != null) {
      return <Alert bsStyle="danger">{error.message}</Alert>
    }
  }
}

Tag.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  tag: PropTypes.object,
  itemState: PropTypes.object,
  itemTagValueIds: PropTypes.array,
  onTagValueClick: PropTypes.func,
}

export default Tag
