import { updateItem } from './updateItem'

export const TOGGLE_IRRELEVANT = 'TOGGLE_IRRELEVANT'

export const togglingIrrelevant = (item) => {
  console.log('Toogle irrelevant on item: ', item.id)
  return {
    type: TOGGLE_IRRELEVANT,
    payload: { item },
  }
}

export const toggleIrrelevant = (item) => {
  return (dispatch) => {
    dispatch(togglingIrrelevant(item))
    const itemData = {
      processings_attributes: [
        { id: item.current_processing.id, irrelevant: !item.current_processing.irrelevant },
      ],
    }
    dispatch(updateItem(item.current_processing.workspace_id, item.id, itemData))
  }
}
