import { combineReducers } from 'redux'
import BundlesReducer from './bundlesReducer'
import CategoriesReducer from './categoriesReducer'
import SourcesReducer from './sourcesReducer'
import GlobalReducer from './globalReducer'
import ItemsReducer from './itemsReducer'
import ItemListNavReducer from './itemListNavReducer'

const allReducers = combineReducers({
  bundles: BundlesReducer,
  categories: CategoriesReducer,
  sources: SourcesReducer,
  global: GlobalReducer,
  items: ItemsReducer,
  itemListNav: ItemListNavReducer,
})

export default allReducers
