// Component modes
export const MODE_SEARCH = 'search'
export const MODE_SEARCH_ONLY = 'search_only'
export const MODE_QUALIFICATION = 'qualification'
export const MODE_SHOW = 'show'
export const MODE_EDIT = 'edit'

// item list nav: how much below the current scroll top the current item top may be to already be considered 'active'
export const ALLOWED_SPACE_ABOVE_ITEM = 200
// item list nav: how much above the actual anchor should the screen scroll to when a nav link is clicked
export const LINK_TARGET_ABOVE_ITEM = Math.ceil(ALLOWED_SPACE_ABOVE_ITEM / 3)
