import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Panel,
  Form,
  FormGroup,
  InputGroup,
  FormControl,
  Button,
  Glyphicon,
  Alert,
} from 'react-bootstrap'

import { MODE_SHOW, MODE_EDIT } from '../constants/workspaceConstants'
import jcssc from '../../util/joined-css-class'
import itemStateClass from '../../util/item-state-class'
import formattedDate from '../../util/formatted-date'

import BundleList from './BundleList'
import CategoryList from './CategoryList'
import CategoryPanel from './CategoryPanel'

// FIXME: implement item updating state (=> disable all inputs?)

class Item extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mode: this.props.mode ? this.props.mode : MODE_SHOW,
      reach: this.props.item.reach ? this.props.item.reach : '',
    }
  }

  // Returns the items current processing
  processing() {
    return this.props.item.current_processing
  }

  // Returns a collection of mandatory tags.
  getMandatoryTags() {
    let allTags = []
    this.props.bundles.ids.forEach((bundleId) => {
      allTags = allTags.concat(this.props.bundles.byId[bundleId].ordered_tags)
    })
    return allTags.filter((tag) => {
      return tag.mandatory == true
    })
  }

  // Return all mandatory tags of the given item currently without a tag value
  getMissingMandatoryTags(item = this.props.item) {
    const mandatoryTags = this.getMandatoryTags()

    return mandatoryTags.filter((tag) => {
      return !tag.ordered_tag_values.some((tagValue) => {
        return item.tag_value_ids.includes(tagValue.id)
      })
    })
  }

  // Determines if all mandatory tags of the given item have a selected tag value.
  hasMissingMandatoryTags(item = this.props.item) {
    return this.getMissingMandatoryTags(item).length > 0
  }

  // Whether there are any clientErrors
  hasClientErrors() {
    return Object.getOwnPropertyNames(this.props.itemState.clientErrors).length > 0
  }

  // Validates the given item and adds clientErrors to this.props.item if invalid. Returns whether it was valid.
  validate(item = this.props.item) {
    if (
      !item.current_processing.processed ||
      item.current_processing.irrelevant ||
      item.current_processing.redundant ||
      !this.hasMissingMandatoryTags(item)
    ) {
      return true
    } else {
      this.setClientErrorsForMissingMandatoryTags(this.getMissingMandatoryTags(item))
      return false
    }
  }

  // For given tags, sets clientErrors that they are missing.
  setClientErrorsForMissingMandatoryTags(tags) {
    let clientErrors = {}
    if (tags.length > 0) {
      let tagsById = {}
      tags.forEach((tag) => {
        tagsById[tag.id] = { message: 'This tag is mandatory.' }
      })
      clientErrors.tagsById = tagsById
    }
    this.props.setItemClientErrors(this.props.item, clientErrors)
  }

  // Determines if item is in show mode
  isShowMode() {
    return this.state.mode == MODE_SHOW
  }

  // Determines if item is in edit mode
  isEditMode() {
    return this.state.mode == MODE_EDIT
  }

  // Determines if item may be modified (except for 'processed')
  isModificationDisabled() {
    return this.isProcessedModificationDisabled() || this.processing().processed
  }

  // Determines if item 'processed' may be modified
  isProcessedModificationDisabled() {
    return this.isShowMode() || this.props.itemState.updating || !!this.props.itemState.error
  }

  handleToggleMode() {
    if (this.props.modeChangable) {
      this.setState({ mode: this.isShowMode() ? MODE_EDIT : MODE_SHOW })
    }
  }

  handleToggleCategory(category) {
    this.props.toggleCategory(this.props.item, category)
  }

  handleToggleFavourite() {
    this.props.toggleFavourite(this.props.item)
  }

  handleToggleAutomatic() {
    this.props.toggleAutomatic(this.props.item)
  }

  handleToggleIrrelevant() {
    this.props.toggleIrrelevant(this.props.item)
  }

  handleToggleRedundant() {
    this.props.toggleRedundant(this.props.item)
  }

  handleToggleProcessed() {
    let checkItem = this.clonedItem()
    checkItem.current_processing.processed = !checkItem.current_processing.processed
    if (this.validate(checkItem)) {
      this.props.toggleProcessed(this.props.item)
    }
  }

  // returns a deep copy of the item
  clonedItem() {
    return JSON.parse(JSON.stringify(this.props.item))
  }

  handleSubmitReach(event) {
    event.preventDefault()
  }

  handleChangeReach(event) {
    this.setState({ reach: event.target.value })
  }

  handleBlurReach(event) {
    this.setState({ reach: event.target.value })
    this.props.updateReach(this.props.item, event.target.value)
  }

  handleKeyDownReach(event) {
    // handle ESC key and reset reach
    if (event.keyCode == 27) {
      this.setState({ reach: this.props.item.reach ? this.props.item.reach : '' })
    }
  }

  handleTagValueClick(tag, tagValue) {
    this.props.toggleTagValue(this.props.item, tag, tagValue)
  }

  cssClass() {
    return jcssc([
      'item',
      itemStateClass(this.props.item, this.props.itemState),
      this.props.className,
    ])
  }

  render() {
    let bundleListProps = {
      mode: this.state.mode,
      disabled: this.isModificationDisabled(),
      bundles: this.props.bundles,
      itemState: this.props.itemState,
      itemTagValueIds: this.props.item.tag_value_ids,
      onTagValueClick: this.handleTagValueClick.bind(this),
    }

    return (
      <div className={this.cssClass()} id={'item-' + this.props.item.id}>
        <Panel>
          <Panel.Heading>
            <Panel.Title>{this.renderHeader()}</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="item-infos">
              {this.renderItemSource()}
              <a
                target="_blank"
                href={this.props.item.effective_link}
                className="item-url"
                rel="noreferrer"
              >
                {this.props.item.effective_link}
              </a>
            </div>
            {this.renderAlert(this.props.itemState.error)}
            <p
              className="lead"
              dangerouslySetInnerHTML={{ __html: this.props.item.description_html }}
            />
            {this.renderCategoryList()}
            <BundleList {...bundleListProps} />
          </Panel.Body>
          <Panel.Footer>{this.renderFooter()}</Panel.Footer>
        </Panel>
      </div>
    )
  }

  renderAlert(error) {
    if (error != null) {
      return (
        <Alert bsStyle="danger">
          <h4>There was an error updating the item.</h4>
          <p>
            Please reload this page and try again. If the error persists, contact technical support.
          </p>
          <p>
            Error details ({error.textStatus}):
            <br />
            {error.error} ({error.responseText}).
          </p>
        </Alert>
      )
    }
  }

  renderHeader() {
    return (
      <div className="item-header">
        <a
          target="_blank"
          href={this.props.item.effective_link}
          className="item-url-link"
          rel="noreferrer"
        >
          <Button className="big-icon-button">
            <Glyphicon glyph="new-window" />
          </Button>
        </a>
        <div className="item-header-info">
          <p>
            <a target="_blank" href={this.props.item.effective_link} rel="noreferrer">
              {this.renderFormattedItemDate()}{' '}
              <span className="id">{'Item-Id: #' + this.props.item.id}</span>
            </a>
          </p>
          <h3 className="panel-title">
            <a target="_blank" href={this.props.item.effective_link} rel="noreferrer">
              {this.props.item.title}
            </a>
          </h3>
        </div>
        {this.renderItemProcessing()}
      </div>
    )
  }

  renderFormattedItemDate() {
    return <span className="date">{formattedDate(new Date(this.props.item.pub_date))}</span>
  }

  renderItemProcessing() {
    let favouriteButtonProps = {
      active: this.processing().favourite,
      disabled: this.isModificationDisabled(),
      onClick: () => this.handleToggleFavourite(),
    }

    let automaticButtonProps = {
      active: this.processing().automatic,
      disabled: this.isModificationDisabled(),
      onClick: () => this.handleToggleAutomatic(),
    }

    let irrelevantButtonProps = {
      active: this.processing().irrelevant,
      disabled: this.isModificationDisabled(),
      onClick: () => this.handleToggleIrrelevant(),
    }

    let redundantButtonProps = {
      active: this.processing().redundant,
      disabled: this.isModificationDisabled(),
      onClick: () => this.handleToggleRedundant(),
    }

    let reachFormGroupProps = {
      name: 'reach',
      type: 'number',
      disabled: this.isModificationDisabled(),
      value: this.state.reach,
      onChange: this.handleChangeReach.bind(this),
      onBlur: this.handleBlurReach.bind(this),
      onKeyDown: this.handleKeyDownReach.bind(this),
    }

    const reachInputGroupCssClass = this.isModificationDisabled()
      ? 'input-group-reach disabled'
      : 'input-group-reach'

    return (
      <div className="item-header-functions">
        <div className="item-header-function-block">
          <Button {...favouriteButtonProps}>
            <Glyphicon glyph="star" /> Favourite
          </Button>
          <Button {...automaticButtonProps}>Automatic</Button>
        </div>
        <div className="item-header-function-block">
          <Button {...irrelevantButtonProps}>Irrelevant</Button>
          <Button {...redundantButtonProps}>Redundant</Button>
        </div>
        <div className="item-header-function-block">
          <Form inline onSubmit={this.handleSubmitReach.bind(this)}>
            <FormGroup>
              <InputGroup className={reachInputGroupCssClass}>
                <InputGroup.Addon>Reach</InputGroup.Addon>
                <FormControl {...reachFormGroupProps} />
              </InputGroup>
            </FormGroup>
          </Form>
        </div>
        <div className="item-header-function-block">{this.renderProcessedButton()}</div>
        {this.renderEditButton()}
      </div>
    )
  }

  renderProcessedButton() {
    let processedButtonProps = {
      className: 'btn-processed',
      active: this.processing().processed,
      disabled: this.isProcessedModificationDisabled(),
      onClick: () => this.handleToggleProcessed(),
    }

    return <Button {...processedButtonProps}>Processed</Button>
  }

  renderEditButton() {
    if (this.props.modeChangable) {
      let editButtonProps = {
        className: 'big-icon-button btn-edit',
        onClick: () => this.handleToggleMode(),
      }

      return (
        <div className="item-header-function-block">
          <Button {...editButtonProps}>
            <Glyphicon glyph={this.isEditMode() ? 'check' : 'edit'} />
          </Button>
        </div>
      )
    }
  }

  renderCategoryList() {
    // only render categories if they are enabled for this workspace
    if (this.props.hasCategories) {
      let categoryProps = {
        mode: this.state.mode,
        disabled: this.isModificationDisabled(),
        categories: this.props.categories,
        itemState: this.props.itemState,
        itemCategoryIds: this.props.item.category_ids,
      }

      if (this.isEditMode()) {
        categoryProps.onCategoryClick = this.handleToggleCategory.bind(this)
        return <CategoryPanel {...categoryProps}></CategoryPanel>
      } else {
        return <CategoryList {...categoryProps}></CategoryList>
      }
    }
  }

  renderFooter() {
    return (
      <div>
        {this.renderLastModifiedInfo()}
        {this.renderProcessedButton()}
      </div>
    )
  }

  renderItemSource() {
    const sourceId = this.props.item.source_id
    const source = this.props.sources.byId[sourceId]
    const label = source != null ? source.name : sourceId

    return <p className="source">Source: {label}</p>
  }

  renderLastModifiedInfo() {
    const lastMod = this.props.item.current_last_modified
    if (lastMod != null) {
      return <p className="modifier-info">Last modified by: {lastMod}</p>
    }
  }
}

Item.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.string,
  modeChangable: PropTypes.bool,
  hasCategories: PropTypes.bool,
  item: PropTypes.object,
  itemState: PropTypes.object,
  categories: PropTypes.object,
  bundles: PropTypes.object,
  sources: PropTypes.object,
  setItemClientErrors: PropTypes.func,
  toggleCategory: PropTypes.func,
  toggleFavourite: PropTypes.func,
  toggleAutomatic: PropTypes.func,
  toggleIrrelevant: PropTypes.func,
  toggleProcessed: PropTypes.func,
  toggleRedundant: PropTypes.func,
  toggleTagValue: PropTypes.func,
  updateReach: PropTypes.func,
}

export default Item
