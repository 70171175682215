import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button } from 'react-bootstrap'

import jcssc from '../../util/joined-css-class'

class TagValue extends Component {
  constructor(props) {
    super(props)
    this.state = { className: this.props.className ? this.props.className : '' }
  }

  isActive() {
    if (this.props.itemTagValueIds == undefined) {
      return false
    } else {
      return (
        this.props.itemTagValueIds.find((tagValueId) => {
          return tagValueId == this.props.tagValue.id
        }) != undefined
      )
    }
  }

  isDisabled() {
    return this.props.disabled || this.props.onClick == undefined
  }

  isActiveClass() {
    return this.isActive() ? 'active' : ''
  }

  handleOnClick() {
    this.props.onClick(this.props.tagValue)
  }

  cssClass() {
    return jcssc(['tag-value', this.isActiveClass(), this.props.className])
  }

  render() {
    let buttonProps = {
      className: this.state.className,
      active: this.isActive(),
      disabled: this.isDisabled(),
    }

    if (this.props.onClick != undefined) {
      buttonProps.onClick = () => this.handleOnClick()
    }

    return (
      <div className={this.cssClass()}>
        <Button {...buttonProps}>{this.props.tagValue.name}</Button>
      </div>
    )
  }
}

TagValue.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  tagValue: PropTypes.object,
  itemTagValueIds: PropTypes.array,
  onClick: PropTypes.func,
}

export default TagValue
