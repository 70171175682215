import update from 'immutability-helper'

import { RESIZE_ITEM_LIST_ITEMS } from '../actions/resizeItemListItems'
import { SCROLL_TOP_CHANGED } from '../actions/scroll'
import { FETCHING_ITEMS_SUCCESS } from '../actions/fetchItems'
import {
  NAVIGATING_ITEM_LIST_NAV_STARTED,
  NAVIGATING_ITEM_LIST_NAV_STOPPED,
} from '../actions/navigatingItemListNav'

import { ALLOWED_SPACE_ABOVE_ITEM } from '../constants/workspaceConstants'

const initialState = {
  itemIds: [],
  yById: {}, // key: the item's id, value: offset top of the "#item-xxx" dom element
  currentId: null, // the item id currently to be considered 'active'
  navAnimations: [], // item ids for which there are currently animations going on (s/o clicked on an entry)
}

// calculates the currently active id without changing the state (i.e. no side effects)
function calculateCurrentId(itemIds, yById) {
  const scrollTop = $(window).scrollTop()
  const itemCount = itemIds.length

  let newCurrentId = itemIds.find((id, index) => {
    if (yById[id] == null) {
      return false
    }

    const y = yById[id] - ALLOWED_SPACE_ABOVE_ITEM

    const nextId = index + 1 < itemCount ? itemIds[index + 1] : null
    const nextY = yById[nextId] != null ? yById[nextId] - ALLOWED_SPACE_ABOVE_ITEM : null

    return scrollTop >= y && (nextY == null || scrollTop < nextY)
  })

  // handle top
  if (newCurrentId == null && itemIds.length > 0) {
    newCurrentId = itemIds[0]
  }

  return newCurrentId
}

// update state: new 'yById'
function updateYById(state) {
  const newYById = state.itemIds.reduce((obj, itemId) => {
    const domSelector = `#item-${itemId}`
    const offset = $(domSelector).offset()
    obj[itemId] = offset != null ? offset.top : null
    return obj
  }, {})
  return update(state, { yById: { $set: newYById } })
}

// update state: calculate and set new 'currentId' (except when 'navAnimations' contains stuff)
function updateCurrentId(state) {
  if (state.navAnimations.length > 0) {
    return state
  }

  const newCurrentId = calculateCurrentId(state.itemIds, state.yById)

  if (newCurrentId != state.currentId) {
    return update(state, { currentId: { $set: newCurrentId } })
  } else {
    return state
  }
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case FETCHING_ITEMS_SUCCESS:
      return updateCurrentId(updateYById(update(state, { itemIds: { $set: payload.itemIds } })))
    case RESIZE_ITEM_LIST_ITEMS:
      return updateCurrentId(updateYById(state))
    case SCROLL_TOP_CHANGED:
      return updateCurrentId(state)
    case NAVIGATING_ITEM_LIST_NAV_STARTED:
      // add state 'navigating to item id ...' to block scroll updates and set currentId
      return update(state, {
        navAnimations: { $push: [payload.itemId] },
        currentId: { $set: payload.itemId },
      })
    case NAVIGATING_ITEM_LIST_NAV_STOPPED: {
      // remove state 'navigating to item id ...'
      const indexInNavAnimations = state.navAnimations.indexOf(payload.itemId)
      if (indexInNavAnimations >= 0)
        return update(state, {
          navAnimations: { $splice: [[indexInNavAnimations, 1]] },
        })
      else {
        return state
      }
    }
    default:
      return state
  }
}
