import update from 'immutability-helper'
import {
  FETCHING_CATEGORIES,
  FETCHING_CATEGORIES_FAILURE,
  FETCHING_CATEGORIES_SUCCESS,
} from '../actions/fetchCategories'

const initialState = {
  byId: {},
  ids: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case FETCHING_CATEGORIES:
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      })
    case FETCHING_CATEGORIES_FAILURE:
      return update(state, {
        loading: { $set: false },
        error: { $set: payload },
      })
    case FETCHING_CATEGORIES_SUCCESS:
      return update(state, {
        byId: { $set: payload.categoriesById },
        ids: { $set: payload.categoryIds },
        loading: { $set: false },
        error: { $set: null },
      })
    default:
      return state
  }
}
