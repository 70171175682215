// Entry point for the build script in your package.json

import React from 'react'
import ReactDOM from 'react-dom'
import WorkspaceApp from './Workspace/startup/WorkspaceApp'

const componentRegistry = {
  WorkspaceApp: WorkspaceApp,
}

document.addEventListener('DOMContentLoaded', () => {
  for (const node of document.getElementsByClassName('react-root')) {
    const componentName = node.getAttribute('data-react-class')
    const Component = componentRegistry[componentName]
    if (typeof Component == 'undefined') {
      console.error('Unknown component: ' + componentName)
      continue
    }

    const props = JSON.parse(node.getAttribute('data-react-props'))
    ReactDOM.render(<Component {...props} />, node)
  }
})
