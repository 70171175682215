// REVIEW: move Item component code in this file and rename this file to it to Item?

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Item from '../components/Item'
import { setItemClientErrors } from '../actions/setItemClientErrors'
import { toggleCategory } from '../actions/toggleCategory'
import { toggleFavourite } from '../actions/toggleFavourite'
import { toggleIrrelevant } from '../actions/toggleIrrelevant'
import { toggleProcessed } from '../actions/toggleProcessed'
import { toggleRedundant } from '../actions/toggleRedundant'
import { toggleTagValue } from '../actions/toggleTagValue'
import { updateReach } from '../actions/updateReach'
import { toggleAutomatic } from '../actions/toggleAutomatic'

// Which part of the Redux global state does our component want to receive as props?
// const mapStateToProps = (state) => (
//   {
//     bundles: state.bundles,
//     tags: state.tags,
//     items: state.items
//   }
// );

const matchDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setItemClientErrors: setItemClientErrors,
      toggleCategory: toggleCategory,
      toggleFavourite: toggleFavourite,
      toggleAutomatic: toggleAutomatic,
      toggleIrrelevant: toggleIrrelevant,
      toggleProcessed: toggleProcessed,
      toggleRedundant: toggleRedundant,
      toggleTagValue: toggleTagValue,
      updateReach: updateReach,
    },
    dispatch
  )

// Don't forget to actually use connect!
// Note that we don't export Item, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(null, matchDispatchToProps)(Item)
