import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Glyphicon } from 'react-bootstrap'

import jcssc from '../../util/joined-css-class'

class ItemListNavigationRefreshButton extends Component {
  cssClass() {
    return jcssc(['item-list-navigation-refresh-button', 'refresh_button', this.props.className])
  }

  render() {
    return (
      <a className={this.cssClass()} href="">
        <Glyphicon glyph="refresh" />
      </a>
    )
  }
}

ItemListNavigationRefreshButton.propTypes = {
  className: PropTypes.string,
}

export default ItemListNavigationRefreshButton
