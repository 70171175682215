import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Alert } from 'react-bootstrap'

import jcssc from '../../util/joined-css-class'

import Bundle from './Bundle'

class BundleList extends Component {
  cssClass() {
    return jcssc(['bundle-list', this.props.className])
  }

  render() {
    return <div className={this.cssClass()}>{this.renderBundles()}</div>
  }

  renderBundles() {
    const bundles = this.props.bundles
    if (bundles.error) {
      const error = bundles.error
      return (
        <Alert bsStyle="danger">
          <h4>There was an error loading the bundles.</h4>
          <p>Please try to relaod this page. If the error persists, contact technical support.</p>
          <p>
            Error details ({error.textStatus}):
            <br />
            {error.error} ({error.responseText}).
          </p>
        </Alert>
      )
    } else if (bundles.loading) {
      return <div className="loading-sign">loading...</div>
    } else {
      return bundles.ids.map((bundleId, index) => {
        let bundleProps = {
          className: 'bundle-' + this.props.mode,
          mode: this.props.mode,
          disabled: this.props.disabled,
          expanded: index == 0,
          bundle: bundles.byId[bundleId],
          itemState: this.props.itemState,
          itemTagValueIds: this.props.itemTagValueIds,
          onTagValueClick: this.props.onTagValueClick,
        }

        return <Bundle key={bundleId} {...bundleProps} />
      })
    }
  }
}

BundleList.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
  bundles: PropTypes.object,
  itemState: PropTypes.object,
  itemTagValueIds: PropTypes.array,
  onTagValueClick: PropTypes.func,
}

export default BundleList
