import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { throttle } from 'throttle-debounce'

import jcssc from '../../util/joined-css-class'

import ItemListNavigationEntry from './ItemListNavigationEntry'
import ItemListNavigationRefreshButton from './ItemListNavigationRefreshButton'

class ItemListNavigation extends Component {
  constructor() {
    super()
    this.handleScroll = throttle(100, this.handleScroll)
  }

  handleScroll(event) {
    let target = event.target || event.srcElement
    return this.props.scrollTopChanged(target.body.scrollTop)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this))
    this.handleScroll.cancel()
  }

  cssClass() {
    return jcssc(['item-list-navigation', this.props.className])
  }

  render() {
    return <div className={this.cssClass()}>{this.renderItemListNavigation()}</div>
  }

  renderItemListNavigation() {
    if (this.props.items.ids.length > 0) {
      return (
        <ul>
          {this.renderItemListNavigationEntries()}
          <li>
            <ItemListNavigationRefreshButton />
          </li>
        </ul>
      )
    }
  }

  renderItemListNavigationEntries() {
    return this.props.items.ids.map((itemId) => {
      const liCssClass = this.props.itemListNav.currentId == itemId ? 'active' : undefined
      return (
        <li key={itemId} className={liCssClass}>
          <ItemListNavigationEntry
            item={this.props.items.byId[itemId]}
            itemState={this.props.items.stateById[itemId]}
            onNavStart={this.props.onNavStart}
            onNavStop={this.props.onNavStop}
          />
        </li>
      )
    })
  }
}

ItemListNavigation.propTypes = {
  className: PropTypes.string,
  items: PropTypes.object,
  itemListNav: PropTypes.object,
  scrollTopChanged: PropTypes.func,
  onNavStart: PropTypes.func,
  onNavStop: PropTypes.func,
}

export default ItemListNavigation
